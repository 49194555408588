<!--会员等级配置-->
<template>
  <div class="balance">
    <div class="search_box">
      <el-button type="primary" plain size="medium" @click="add">添加会员等级</el-button>
    </div>

    <!-- 表格 -->
    <el-table :data="tableData" border :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }">
      <el-table-column label="等级标题" align="center" prop="levelName">
      </el-table-column>
      <el-table-column label="启动状态" align="center" prop="enable">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.enable" disabled></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="成长值" align="center" prop="experience">
      </el-table-column>
      <el-table-column label="主题色值" align="center" prop="fontColor">
      </el-table-column>
      <el-table-column width="200" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="medium" @click="edit(scope.row)">等级设置</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 编辑弹框 -->
    <el-dialog :visible.sync="infoShow" :title="title" :close-on-click-modal="false" width="50%" center @close="close"
      >
      <el-form :model="levelForm" :rules="rules" ref="levelForm">
        <el-form-item label="等级名称" label-width="120px" prop="levelName">
          <el-input v-model="levelForm.levelName" placeholder="请输入等级名称"></el-input>
        </el-form-item>
        <el-form-item label="是否启用该等级" prop="enable" required>
          <el-switch v-model="levelForm.enable" :disabled="levelForm.enable"></el-switch>
          <div class="tips">*等级开启后不支持再关闭</div>
        </el-form-item>

        <el-form-item label="成长值" label-width="120px" prop="experience">
          <el-input v-model="levelForm.experience" type="number" :step="1" placeholder="请输入成长值" @keydown.native="(e) =>
      (e.returnValue = ['e', 'E', '+', '-', '.'].includes(e.key)
        ? false
        : e.returnValue)
        " @input="numberInput"></el-input>
          <div style="margin-left: 0;">成长值=累计充值本金</div>
        </el-form-item>

        <el-form-item label="等级主题背景" label-width="120px" prop="backgroundFileId">
          <div class="upload-btn" @click="onUpload()">
            <img v-if="levelForm.backgroundFileId" :src="levelForm.backgroundFileUrl" class="upload-img" />
            <div v-else>
              <i class="el-icon-plus uploader-icon"></i>
              <div class="tip">上传照片</div>
            </div>
          </div>
          <div style="width: 200%;">上传一张500kb以内.jpg.png格式的图片，建议尺寸750x332</div>
        </el-form-item>
        <el-form-item label="等级主题色值" label-width="120px" prop="fontColor">
           <el-input v-model="levelForm.fontColor" placeholder="请输入色值如 #000000" maxlength="7"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </div>
    </el-dialog>
    <UpLoad @fileIds="getFileIds" ref="up" :fileType="1"></UpLoad>
  </div>
</template>
<script>
import { pickerOptions } from "@/db/objs";
import { addLevelConfig, editLevelConfig, getLevelConfigDetail, getLevelConfig } from '@/api/user/level.js'
import UpLoad from "@/components/upload";
import { getFileList } from "@/api/common";
export default {
  components: { UpLoad },
  data() {
    return {
      pickerOptions,
      dateToDate: [], //日期
      tableParams: {},
      tableData: [],
      infoShow: false,
      title: "添加会员等级",
      levelForm: {
        levelConfigId: '',
        backgroundFileId: "", //营业执照
        backgroundFileUrl: "",
        enable: false, //是否启用
        experience: '', //成长值
        fontColor: "", //主体色值
        levelName: "", //等级名称
      },
      rules: {
        levelName: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
        ],
        experience: [
          { required: true, message: '请输入成长值', trigger: 'blur' },
        ],

        backgroundFileId: [
          { required: true, message: '请上传主题背景图片', trigger: 'blur' },
        ],
        fontColor: [
          { required: true, message: '请输入主题色值', trigger: 'blur' },
        ]
      }
    };
  },

  mounted() {
    this.getTableList();
  },
  methods: {
    // 获取等级列表
    getTableList() {

      getLevelConfig(this.tableParams).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data;
        }
      });
    },
    close(){
      this.$refs.levelForm.clearValidate()
    },
    reset() {
      this.levelForm = {
        levelConfigId: '',
        backgroundFileId: "", //营业执照
        backgroundFileUrl: "",
        enable: false, //是否启用
        experience: "", //成长值
        fontColor: "", //主体色值
        levelName: "", //等级名称
      }
    },
    // 【监听】表格重载
    reload() {

      this.getTableList();
    },
    // 【监听】文件上传
    onUpload() {
      this.$refs.up.onUpload();
    },
    // 【请求】获取文件信息
    getFileList(fileIds) {
      let data = {
        fileIds
      };
      getFileList(data).then(res => {
        if (res.isSuccess == "yes") {
          this.levelForm.backgroundFileId = res.data[0].attId
          this.levelForm.backgroundFileUrl = res.data[0].url
        }

        console.log(this.levelForm)
      })
    },
    // 【监听】获取上传文件ID集合
    getFileIds(fileIds) {
      this.getFileList(fileIds);
    },
    add() {
      this.reset()
      this.infoShow = true;
      this.title = "添加会员等级";
    },
    edit(row) {
      this.infoShow = true;
      this.title = "等级设置";
      getLevelConfigDetail({ levelConfigId: row.levelConfigId }).then(res => {
        this.levelForm = res.data
      })
    },
    numberInput(value) {
      if (value.length > 8) { this.levelForm.experience = value.slice(0, 8) }
    },
    onSubmit() {
      this.$refs.levelForm.validate((valid) => {
        if (valid) {
          // 表单验证通过，执行提交逻辑
          if (this.levelForm.levelConfigId) {
            editLevelConfig(this.levelForm).then(res => {
              this.$refs.up.onClear();
              this.reload();
            })
          } else {
            addLevelConfig(this.levelForm).then(res => {
              this.$refs.up.onClear();
              this.reload();
            })
          }

          this.infoShow = false;
        } else {
          // 表单验证失败，提示用户
          // this.$message.error("表单验证失败，请检查输入！");
          return false;
        }
      });
    }

  },
};
</script>
<style lang="scss" scoped>
.balance {
  padding: 0.15rem;
  box-sizing: border-box;
}

.search_box {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.15rem;
}

.search_L {
  display: flex;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}

.form-footer {
  display: flex;
  justify-content: flex-end;
}

.upload-btn {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  width: 1.6rem;
  height: 1.6rem;
  position: relative;

  .tip {
    position: absolute;
    left: 50%;
    bottom: 10%;
    transform: translateX(-50%);
  }
}

.upload-btn:hover {
  border-color: #409eff;
}

.uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 1.6rem;
  height: 1.6rem;
  line-height: 1.6rem;
  text-align: center;
}

.upload-img {
  width: 1.6rem;
  height: 1.6rem;
  display: block;
}

.tips {
  width: 400px;
  color: red;
  margin-left: 120px;
}
</style>
